<template>
  <div class="boxUs">
    <el-row class="dash-board">
      <el-button type="primary" @click="getOne" class="inquire">查询</el-button>
      <el-button type="warning" @click="reset" class="reset">重置</el-button>
      <el-button type="primary" @click="add" class="adduser">+新增单位</el-button>
      <el-upload
            :action= "url"
            class="upload-btn"
            accept=".xls, .xlsx"
            :on-success="handleAvatarSuccess"
            :show-file-list="false"
            :headers="toke"
          >
            <el-button type="primary" class="adduserTwo">导入</el-button>
          </el-upload>
      <!-- <el-button type="primary" @click="adduserVisibleTwo = true" class="adduserTwo">导入</el-button> -->
      <p class="name" style="font-size: 12px">单位名称:</p>
      <el-input v-model="CompanyName" class="in2" placeholder="请输入单位名称"></el-input>
    </el-row>
    <!-- 列表数据 -->
    <el-row class="bg">
    <el-table
      :data="tableData"
      style="width: 100%;">
      <el-table-column
        label="id"
        align="center"
        width="250">
        <template slot-scope="scope">
          <span>{{ scope.row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="单位名称"
        align="center"
        width="250">
        <template slot-scope="scope">
          <span>{{ scope.row.value }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="描述"
        align="center"
        width="250">
        <template slot-scope="scope">
          <span>{{ scope.row.description }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleEdit(scope.row)">修 改</el-button>
          <el-button size="mini" type="danger" @click="Delete(scope.row)">删 除</el-button>
        </template>
      </el-table-column>
    </el-table>
    </el-row>
    <!-- 新增单位 -->
    <el-dialog
      :title="title"
      :visible.sync="adduserVisible"
      width="700"
      center>
      <el-form
        :model="ruleForm2"
        status-icon
        :rules="rules2"
        ref="ruleForm2"
        label-width="0"
        class="demo-ruleForm"
      >
       <el-form-item prop="name" label="单位名称" :label-width="formLabelWidth">
          <el-input v-model="ruleForm2.name" auto-complete="off" placeholder="请输入单位名称"></el-input>
        </el-form-item>
        <el-form-item label="描述" :label-width="formLabelWidth">
          <el-input v-model="ruleForm2.description" auto-complete="off" placeholder="请输入描述，非必填"></el-input>
        </el-form-item>
      </el-form>
       <span slot="footer" class="dialog-footer">
        <el-button @click="adduserVisible = false">取 消</el-button>
        <el-button type="primary" v-if="openType == 0" @click="submitForm('ruleForm2')">确 定</el-button>
        <el-button type="primary" v-else @click="submitForm2('ruleForm2')">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 列表数据 -->
    <!-- 分页 -->
    <el-row style="margin-top:20px">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          class="pag">
      </el-pagination>
    </el-row>
  </div>
</template>

<script>
  import { getUrl } from '@/utils/request.js'
  import { getCookie } from '@/utils/base'
  import { mapMutations } from 'vuex'
  import { findUnit, addUnit, updateUnit, delDel } from '@/api/training.js'
  export default {
    name: 'Leavefer',
    data () {
      return {
         url: getUrl() + '/dev-api/importExcel',
         toke: {
           Authorization: getCookie('Authorization')
         },
        tableData: [],
        CompanyName: '',
        title: '',
        adduserVisible: false,
        adduserVisibleTwo: false,
        openType: 0,
        total: 0,
        pageNum: 1,
        pageSize: 10,
        formLabelWidth: '100px',
        ruleForm2: {
          name: '',
          description: ''
        },
        rules2: {
          name: [{ required: true, message: '请输入单位名称', trigger: 'blur' }]
        },
        form: {
          file: ''
        }
      }
    },
    mounted () {
      this.getOne()
    },
    methods: {
      // 导入功能
      handleAvatarSuccess (res, file) {
        console.log(res)
        if (res.code === '0') {
          this.$notify({
            message: '导入成功',
            type: 'success'
          })
        } else {
          this.$notify.error({
                message: res.data.msg
          })
        }
      },
      ...mapMutations({
        saveUser: 'SAVE_USER'
      }),
      // 查询
      getOne () {
        if (this.CompanyName === '') {
          this.CompanyName = null
        }
        const query = {
          name: this.CompanyName,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
        findUnit(query).then(res => {
          if (res.data.code === '0') {
            this.tableData = res.data.data.rows
            this.total = res.data.data.total // 查询总条数
          }
        })
      },
      // 修改单位按钮
      handleEdit (row) {
        console.log(row)
        this.title = '修改单位'
        this.ruleForm2 = row
        this.ruleForm2.name = row.value
       this.adduserVisible = true
       this.openType = 1
      },
      // 修改单位名称
      submitForm2 (ruleForm2) {
        this.$refs[ruleForm2].validate(valid => {
          if (valid) {
            const query = {
              id: this.ruleForm2.id,
              value: this.ruleForm2.name,
              description: this.ruleForm2.description,
              parentId: 2000
            }
            updateUnit(query).then((resp) => {
              this[ruleForm2] = {}
              if (resp.data.code === '0') {
                this.$notify({
                  message: '修改成功。',
                  type: 'success'
                })
                this.getOne()
                this.adduserVisible = false
              } else {
                this.$notify.error({
                  message: resp.data.msg
                })
              }
            })
          }
        })
      },
      add () {
        this.ruleForm2 = {}
        this.title = '新增单位'
        this.adduserVisible = true
      },
      // 新增单位名称
      submitForm (ruleForm2) {
        this.$refs[ruleForm2].validate(valid => {
          if (valid) {
            const query = {
              value: this.ruleForm2.name,
              description: this.ruleForm2.description,
              parentId: 2000
            }
            addUnit(query).then((resp) => {
              this[ruleForm2] = {}
              if (resp.data.code === '0') {
                this.$notify({
                  message: '新增成功。',
                  type: 'success'
                })
                this.getOne()
                this.adduserVisible = false
              } else {
                this.$notify.error({
                  message: resp.data.msg
                })
              }
            })
          }
        })
      },
      // 删除
      Delete (row) {
        const id = row.id
        this.$confirm('确定删除此用户, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          delDel(id).then(res => {
            if (res.data.code === '0') {
              this.$notify({
                  message: '删除成功。',
                  type: 'success'
              })
              this.getOne()
            } else {
              this.$notify.error({
                message: res.data.msg
              })
            }
          })
        }).catch(() => {
        })
      },
      // 清空
      reset () {
        this.CompanyName = ''
      },
      // 分页
      handleSizeChange(e) {
        this.pageSize = e
        this.getOne()
      },
      handleCurrentChange(e) {
        this.pageNum = e
        this.getOne()
      }
    }
  }
</script>

<style scoped>
  .boxUs{margin-top: -10px}
  .dash-board {
    display: flex;
    background-color: white;
    height: 75px;
  }
.bg{margin-top: 15px; padding: 20px; background-color: white;}
  .inquire{
    position: absolute;
    top: 20px;
    left:350px;
  }
  .reset{
    position: absolute;
    top: 20px;
    left: 410px;
  }
  .in1{
    width: 150px;
    position: absolute;
    top: 88px;
    left: 290px;
  }
  .in2{
    width: 215px;
    position: absolute;
    top: 20px;
    left: 90px;
  }
  .in3{
    width: 150px;
    position: absolute;
    top: 120px;
    left: 250px;
  }
  .in4{
    width: 150px;
    position: absolute;
    top: 120px;
    left: 480px;
  }
  .name{
    font-size: 15px;
    position: absolute;
    top: 15px;
    left: 20px;
  }
  .one{
    font-size: 15px;
    position: absolute;
    top: 88px;
    left: 470px;
  }
  .iocpn{
    font-size: 15px;
    position: absolute;
    top: 115px;
    left: 210px;
  }
  .two{
    font-size: 15px;
    position: absolute;
    top: 115px;
    left: 425px;
  }
  .time{
    font-size: 15px;
    position: absolute;
    top: 65px;
    left: 20px;
  }
  .data{
    position: absolute;
    top: 70px;
    left: 90px;
  }
  .zhuangtai{position: absolute; top:15px; left: 350px}
  .opst3{ position: absolute; top:20px;left: 390px}
  .adduser{ position: absolute; top: 20px; left: 485px;}
  .adduserTwo{ position: absolute; top: 20px; left: 595px;}
</style>
